import {useState, useRef} from 'react';
import './index.css';

const playIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 199 225"><path d="M8.04 1.74C18.72-.89 26.18 5.45 35 10.58l51 29.57 54 31.28 44 25.77c5.69 3.42 13.28 7.36 13.88 14.8.54 6.73-3.79 9.82-8.88 13.14l-31 18.06-82 47.22-56 31.79C5.89 228.63 1.02 221.64 1 208V15C1.09 8.68 2.31 4.99 8.04 1.74Z"/></svg>;
const pauseIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 384"><path d="M9 .57 35 0h45c12.78.06 15.98 4.81 16 17v350c-.02 13.19-3.81 16.98-17 17H15c-11.39-.14-14.95-5.11-15-16V15C.09 7.52 1.63 3.47 9 .57Zm160 0L195 0h45c12.78.06 15.98 4.81 16 17v350c-.02 13.19-3.81 16.98-17 17h-64c-11.39-.14-14.95-5.11-15-16V15c.09-7.48 1.63-11.53 9-14.43Z"/></svg>;

const AudioObject = (props) => {

    const [playing, setPlaying] = useState(false);
    const [mouseDownState, setMouseDown] = useState(false);
    const audioObject = useRef();
    const currentTime = useRef();
    const fullTime = useRef();
    const progress = useRef();
    const timeline = useRef();

    document.onmousemove = function(e){
        if (mouseDownState){
            console.log("e");
            var bounds = timeline.current.getBoundingClientRect();
            var time = (e.clientX - bounds.left) / bounds.width * audioObject.current.duration;
            audioObject.current.currentTime = time;
        }
    }

    document.onmouseup = function(){setMouseDown(false);}
    window.onblur = function(){setMouseDown(false);}

    const handleTime = function(){

        var seconds = ("0" + Math.floor(audioObject.current.currentTime % 60)).slice(-2);
        var newTime = `${Math.floor(audioObject.current.currentTime / 60)}:${seconds}`;
        var progressValue = audioObject.current.currentTime / audioObject.current.duration * 100;

        progress.current.style.width = `${progressValue}%`;
        currentTime.current.innerText = `${newTime}`;
    }

    return (
        <div className="audio float">

            <audio ref={audioObject} onTimeUpdate={handleTime} onEnded={function(){setPlaying(false);}} onLoadedMetadata={function(){
                    var duration = audioObject.current.duration;
                    var seconds = ("0" + Math.floor(duration % 60)).slice(-2);
                    var newTime = `${Math.floor(duration / 60)}:${seconds}`;
                    fullTime.current.innerText = newTime;
            }}>
                <source src={props.audio}/>
            </audio>

            <div className="audio-text">
                <h1 className="audio-title">{props.title}</h1>
                <div className="audio-info">
                    <div data-interact ref={timeline} className="audio-timeline" onMouseDown={function(e){
                        setMouseDown(true);
                        var bounds = timeline.current.getBoundingClientRect();
                        var time = (e.clientX - bounds.left) / bounds.width * audioObject.current.duration;
                        audioObject.current.currentTime = time;
                    }}>
                        <div ref={progress} className="current-timeline"></div>
                    </div>
                    <div className="audio-times">
                        <span ref={currentTime}>0:00</span>
                        <span ref={fullTime}>0:00</span>
                    </div>
                </div>
                <div data-interact className="song-button"onClick={function(){setPlaying(!playing); if (!playing) audioObject.current.play(); else audioObject.current.pause();}}>
                    {playing ? pauseIcon : playIcon}
                </div>
            </div>
        </div>
    );
}

export default AudioObject;