import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';


const animation = () => {

    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {

        gsap.set(".phase2", {autoAlpha: 0});
        gsap.set(".phase3", {autoAlpha: 0});
        gsap.set(".about-photo.last", {clipPath: `inset(0% 100% 0% 0%)`})

        gsap.to(".phase1", {
            autoAlpha: 0,
            scrollTrigger: {
                trigger: ".about-content",
                start: "125% bottom",
                end: "150% bottom",
                scrub: 0.05,
            }
        });

        gsap.to(".phase2", {
            autoAlpha: 1,
            scrollTrigger: {
                trigger: ".about-content",
                start: "150% bottom",
                end: "175% bottom",
                scrub: 0.05,
            }
        });

        gsap.to(".about-photo:not(.last)", {
            autoAlpha: 0,
            scrollTrigger: {
                trigger: ".about-content",
                start: "175% bottom",
                end: "275% bottom",
                scrub: 0.05,
            }
        })

        gsap.to(".about-photo.last", {
            clipPath: `inset(0% 0% 0% 0%)`,
            scrollTrigger: {
                trigger: ".about-content",
                start: "175% bottom",
                end: "275% bottom",
                scrub: 0.05,
            }
        })

        gsap.to(".phase2 .a", {
            autoAlpha: 0,
            scrollTrigger: {
                trigger: ".about-content",
                start: "225% bottom",
                end: "250% bottom",
                scrub: 0.05,
            }
        });

        gsap.to(".phase2 .b", {
            autoAlpha: 0,
            scrollTrigger: {
                trigger: ".about-content",
                start: "225% bottom",
                end: "250% bottom",
                scrub: 0.05,
            }
        });

        gsap.to(".phase3", {
            autoAlpha: 1,
            scrollTrigger: {
                trigger: ".about-content",
                start: "250% bottom",
                end: "275% bottom",
                scrub: 0.05,
            }
        });

        gsap.to(".about-content", {
            scrollTrigger: {
                trigger: ".about-content",
                pin: true,
                start: "50% center",
                end: "300% bottom",
            }
        });

        gsap.to(".home-wrapper", {
            autoAlpha: 0,
            scrollTrigger: {
                trigger: ".about-section",
                scrub: 0.05,
                start: "top bottom",
                end: "top top",
            }
        });

        gsap.to(".home-text", {
            scale: 1.05,
            y: 0,
            yPercent: -200,
            scrollTrigger: {
                trigger: ".about-section",
                scrub: 0.05,
                start: "top bottom",
                end: "top top",
            }
        });

        gsap.set(".headshot", {translateX: "-50%",});

        gsap.to(".headshot", {
            scale: 1.1,
            y: 0,
            yPercent: -25,
            scrollTrigger: {
                trigger: ".about-section",
                scrub: 0.05,
                start: "top bottom",
                end: "top top",
            }
        });

        var allNSFloats = document.querySelectorAll(".ns-float");
        var allFloats = document.querySelectorAll(".float");

        gsap.set(allNSFloats, {
            yPercent: 0,
            y: 50,
            autoAlpha: 0,
        });

        gsap.to(allNSFloats, {
            delay: 0.5,
            autoAlpha: 1,
            y: 0,
            yPercent: 0,
            stagger: 0.05,
            
        });

        gsap.set (".about-content", {
            autoAlpha: 0,
            yPercent: 0,
            y: 100,
        });

        gsap.to(".about-content", {
            autoAlpha: 1,
            yPercent: 0,
            y: 0,
            scrollTrigger: {
                trigger: ".about-content",
                start: "100px bottom",
                end: "bottom bottom",
                scrub: true,
            }
        });

        ScrollTrigger.batch(allFloats, {
            start: "10px bottom",
            toggleActions:"restart play reverse reset",
            end: "bottom bottom",
            onEnter: batch => {
                gsap.to(batch, {autoAlpha: 1, y: 0, yPercent: 0, stagger: 0.1, overwrite: false,})
            },
            onLeaveBack: batch => {
                gsap.to(batch, {autoAlpha: 0, y: 50, yPercent: 0, stagger: 0.1, overwrite: false,})
            }
        })

        gsap.set(allFloats, {
            yPercent: 0,
            y: 40,
            autoAlpha: 0,
        });

        
        gsap.to(".masthead", {
            scrollTrigger: {
                trigger: ".about-section",
                start: "top 50px",
                end: "bottom 50px",
                onEnter: () => {document.querySelector(".masthead").classList.add("masthead-fade");},
                onEnterBack: () => {document.querySelector(".masthead").classList.add("masthead-fade");},
                onLeave: () => {document.querySelector(".masthead").classList.remove("masthead-fade");},
                onLeaveBack: () => {document.querySelector(".masthead").classList.remove("masthead-fade");},
            }
        })
/*
        gsap.to(".masthead", {
            scrollTrigger: {
                trigger: ".work-wrapper",
                start: "top 50px",
                end: "bottom 50px",
                onEnter: () => {document.querySelector(".masthead").classList.add("dark");},
                onLeave: () => {document.querySelector(".masthead").classList.remove("dark");},
                onLeaveBack: () => {document.querySelector(".masthead").classList.remove("dark");},
            }
        })*/

    });

    return () => {ctx.revert();}

};

export default animation;