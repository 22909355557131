import {useRef, useState} from 'react';
import emailjs from 'emailjs-com';
import './index.css';

const arrowSVG = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 286 256"><path d="M143.44 250.24c-3.58-3.57-5.8-8.49-5.8-13.93 0-5.45 2.22-10.37 5.8-13.93l75.03-74.7H22.01c-.34.02-.7.03-1.06.03C9.76 147.71.62 138.99 0 128c.61-11 9.76-19.73 20.95-19.73.36 0 .71.01 1.06.03h196.46l-75.03-74.7c-3.57-3.56-5.79-8.48-5.79-13.92 0-5.43 2.22-10.35 5.79-13.92A19.76 19.76 0 0 1 157.42 0c5.46 0 10.41 2.2 13.99 5.77l108.78 108.3c3.59 3.56 5.81 8.48 5.81 13.91v.52c-.14 5.22-2.32 9.94-5.79 13.38L171.42 250.19c-3.56 3.58-8.5 5.81-13.96 5.81h-.06c-5.45 0-10.39-2.21-13.96-5.77v.01Z"/></svg>;

const ContactForm = (props) => {

    const form = useRef();
    const flag = useRef();
    const name = useRef();
    const email = useRef();
    const message = useRef();
    const [page, setPage] = useState(0);

    const sendEmail = (e) => {
        e.preventDefault();
        form.current.classList.add("invalid-email");

        emailjs.sendForm(process.env.REACT_APP_SERVICE, process.env.REACT_APP_TEMPLATE, form.current, process.env.REACT_APP_PUBLIC_KEY)
        .then((result) => {
            setPage(page + 1);
            flag.current.innerText = "Your message has been sent.";
        }, (error) => {
            setPage(page + 1);
            flag.current.innerText = error;
        });

    }

    const changePage = function(temp){
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.current.value) === true && page === 1) {setPage(temp); email.current.blur(); form.current.classList.remove("invalid-email");} 
        else if (name.current.value.length > 1 && page === 0) { setPage(temp); name.current.blur(); form.current.classList.remove("invalid-email");}
        else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.current.value) === false && page === 1) {
            form.current.classList.add("invalid-email");
        }
    }

    return (
        <form ref={form} onSubmit={(e) => {sendEmail(e);}}>
            <div className="slip float">
                <div className="panel-holder">
                    <div className="panel" style={{transform: `translateX(${page * -100}%)`}}>
                        <div className="panel-title">What is your name?</div>
                        <input placeholder="Your Name" required ref={name} type="text" name="name" onKeyDown={function(e){ if (e.keyCode === 13) changePage(page + 1);}}></input>
                    </div>
                    <div className="panel" style={{transform: `translateX(${page * -100}%)`}}>
                        <div className="panel-title">What is your email?</div>
                        <input placeholder="Your Email" required ref={email} type="text" name="email" onKeyDown={function(e){ if (e.keyCode === 13) changePage(page + 1);}}></input>
                    </div>
                    <div className="panel" style={{transform: `translateX(${page * -100}%)`}}>
                        <div className="panel-title">What do you have to say?</div>
                        <textarea placeholder="Your Message" required ref={message} name="message" minLength={4}></textarea>
                    </div>
                    <div className="panel" style={{transform: `translateX(${page * -100}%)`}}>
                        <div ref={flag} className='flag'>Your message has been received.</div>
                    </div>
                </div>

                {page === 2 ?
                    <button type="submit" className="submit-button">
                        {arrowSVG}
                    </button>
                :
                    <div className="submit-button" onClick={function(){changePage(page + 1);}}>
                        {arrowSVG}
                    </div>
                }

            </div>

        </form>
    )
}

export default ContactForm;